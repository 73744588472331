
export function inputSettingsAdapter(payload = null) {


  if (!payload.data?.data) return;

  const SETTINGS = payload.data.data;

  return {
    // reports,
    uuid: SETTINGS.uuid,
    status: SETTINGS.status,
    submitReportToRespondent: SETTINGS.default_report,
    oneSheetExcel: !SETTINGS.lists,
    points: SETTINGS.scale,
    defaultPoints: SETTINGS.value_default,
    defaultScales: SETTINGS.scale_default,
    scales: SETTINGS.scales,
    tasks: SETTINGS.tasks,
    responseTime: SETTINGS.tasks_times,
    answersToTasks: SETTINGS.tasks_answer,
    rawPointForTasks: SETTINGS.tasks_scales,
    matchPercentage: SETTINGS.tasks_assignments,
    respondentData: SETTINGS.respondent,
    respondentViewSettings: SETTINGS.respondent_display_settings,
    respondentAdditionalQuestionnaire: SETTINGS.respondent_datacube,
    reports: SETTINGS.variants.map((variant) => {
      return {
        uuid: variant.uuid,
        name: variant.title,
        code_name: variant.name,
        status: variant.status,
        variant: variant.variant,
        blocks: variant.blocks.map((block) => {
          return {
            uuid: block.uuid,
            name: block.title,
            code_name: block.name,
            required: block.unchanged,
            status: block.status,
            order: block.order,
          }
        }),
      }
    }),
  }
}
export function outputSettingsAdapter(payload = null) {


  if (!payload) return null;

  let settings = {};

  if (payload.oneSheetExcel !== undefined) settings.lists = !payload.oneSheetExcel;
  if (payload.points !== undefined) settings.scale = payload.points;
  if (payload.defaultPoints !== undefined) settings.value_default = payload.defaultPoints;
  if (payload.defaultScales !== undefined) settings.scale_default = payload.defaultScales;
  if (payload.scales !== undefined) settings.scales = payload.scales;
  if (payload.tasks !== undefined) settings.tasks = payload.tasks;
  if (payload.responseTime !== undefined) settings.tasks_times = payload.responseTime;
  if (payload.answersToTasks !== undefined) settings.tasks_answer = payload.answersToTasks;
  if (payload.rawPointForTasks !== undefined) settings.tasks_scales = payload.rawPointForTasks;
  if (payload.matchPercentage !== undefined) settings.tasks_assignments = payload.matchPercentage;
  if (payload.respondentData !== undefined) settings.respondent = payload.respondentData;
  if (payload.respondentViewSettings !== undefined) settings.respondent_display_settings = payload.respondentViewSettings;
  if (payload.respondentAdditionalQuestionnaire !== undefined) settings.respondent_datacube = payload.respondentAdditionalQuestionnaire;

  return settings;
}
export function outputSettingsAdapterDefaultReport(payload = null) {


  if (!payload) return null;

  let settings = {};

  if (payload.submitReportToRespondent !== undefined) settings.default_report = payload.submitReportToRespondent;
  if (payload.reports !== undefined) {
    settings.variants = payload.reports.map((report) => ({
      name: report.code_name,
      title: report.name,
      status: report.status,
      uuid: report.uuid,
      variant: report.variant,
      blocks: {},

      /*
      blocks: report.blocks.map((block) => ({
        name: block.code_name,
        title: block.name,
        order: block.order,
        // required: block.required,
        status: block.status,
        uuid: block.uuid,
      })),
       */
    }));
  }

  return settings;
}